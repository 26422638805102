import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './MovieDetails.css';
import { IMovieDetails, IMovieVideo } from './types';
import { settings } from './settings';

export default function MovieDetails() {
  let { movieId }: any = useParams();
  let [movie, setMovie] = useState<IMovieDetails>();
  let [videos, setVideos] = useState<IMovieVideo[]>();

  useEffect(() => {
    getMovieDetails(movieId).then(setMovie);
  }, [movieId]);
  useEffect(() => {
    getVideos(movieId).then(setVideos);
  }, [movieId]);

  if (!movie || !videos) {
    return null;
  }

  return (
    <div>
      <div>
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            title="Trailer Video"
            width="480"
            height="270"
            src={generateTrailerUrl()}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <div className="px-3 pt-2">
          <h4 className="pb-1 font-medium">{movie.title}</h4>
          <p className="font-sans text-xs font-thin text-grey-light">
            {movie.overview}
          </p>

          <hr className="h-px my-1 bg-grey-darker" />

          <h5 className="font-medium pb-1">Information</h5>
          <dl className="text-xs">
            <div>
              <dt>In Theaters</dt>
              <dd>{movie.release_date}</dd>
            </div>
            <div>
              <dt>Genres</dt>
              <dd>
                {movie.genres
                  .map((x) => x.name)
                  .reduce((prev, curr) => `${prev}, ${curr}`)}
              </dd>
            </div>
            <div>
              <dt>Run Time</dt>
              <dd>{movie.runtime}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );

  function generateTrailerUrl(): string | undefined {
    // TODO: what to do about non YouTube trailers?
    const firstTrailer = videos?.find(
      (x) => x.type === 'Trailer' && x.site === 'YouTube'
    );

    return firstTrailer
      ? `https://www.youtube.com/embed/${firstTrailer.key}?rel=0&amp;showinfo=0`
      : undefined;
  }
}

function getMovieDetails(id: number): Promise<IMovieDetails> {
  const url = `${settings.tmdbBaseUrl}/movie/${id}?api_key=${settings.tmdbApiKey}`;

  return fetch(url, { method: 'get' }).then((response) => response.json());
}

function getVideos(movieId: number): Promise<IMovieVideo[]> {
  const url = `${settings.tmdbBaseUrl}/movie/${movieId}/videos?api_key=${settings.tmdbApiKey}`;

  return fetch(url, { method: 'get' })
    .then((response) => response.json())
    .then((x) => x.results);
}
