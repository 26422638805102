import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import InTheaters from './InTheaters';
import MovieDetails from './MovieDetails';
import './App.css';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/movies/:movieId">
          <MovieDetails />
        </Route>
        <Route path="/">
          <InTheaters />
        </Route>
      </Switch>
    </Router>
  );
}
