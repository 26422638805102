import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { IMovie } from './types';
import { settings } from './settings';

export default function InTheaters() {
  const [movies, setMovies] = useState<IMovie[]>([]);
  useEffect(() => {
    fetchNowPlaying().then((m) => {
      setMovies(m);
    });
  }, []);

  return (
    // TODO: fix first column of posters not loading
    // TODO: fix remembering page position when navigating back from detail view
    // TODO: make images lazily loaded
    // TODO: Display the movie title text in a transparent box overlaying the bottom of the image
    <div className="flex flex-wrap -mx-1 sm:-mx-2 lg:-mx-3">
      {movies.map((movie) => (
        <Link
          to={'/movies/' + movie.id}
          key={movie.id}
          className="w-1/2 p-1 sm:w-1/3 sm:p-2 lg:w-1/5 lg:p-3 xl:w-1/6"
        >
          <div>
            <picture>
              <source
                media="(max-width: 400px)"
                src={'https://image.tmdb.org/t/p/w342' + movie.poster_path}
              />
              <source
                media="(max-width: 800px)"
                src={'https://image.tmdb.org/t/p/w500' + movie.poster_path}
              />
              <img                
                src={'https://image.tmdb.org/t/p/w780' + movie.poster_path}
                alt="Movie Poster"
              />
            </picture>
          </div>
        </Link>
      ))}
    </div>
  );

  function fetchNowPlaying(): Promise<IMovie[]> {
    const url = `${settings.tmdbBaseUrl}/movie/now_playing?api_key=${settings.tmdbApiKey}&page=1`;

    return fetch(url, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => data.results);
  }
}
